<template>
  <div id="app">
    <!-- <div class="mask" v-show="show"></div> -->
    <router-view></router-view>
    <el-backtop :right="40" :bottom="40">回到顶部</el-backtop>
  </div>
</template>

<script>
import { userInfo, cartNum } from "@/api/user";
import { getToken } from "@/utils/auth";
export default {
  name: "app",
  data() {
    return {
      res: {},
      show: false
    };
  },
  created() {
    // this.show = true;
    // setTimeout(() => {
    //   this.show = false;
    // }, 100);
  },
  watch: {
    // $route(to, from) {
    //   this.show = true;
    //   setTimeout(() => {
    //     this.show = false;
    //   }, 500);
    // }
  },
  mounted() {
    if (getToken()) {
      this.$cookie.set("userId", getToken(), { expires: "Session" });
      this.getUserInfo();
      this.getCartSum();
    }
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      userInfo({}).then(res => {
        // 保存到vuex里面
        this.$store.dispatch("saveUserName", res.data.name);
      });
    },
    // 获取购物车商品数量
    getCartSum() {
      cartNum({}).then(res => {
        // 保存到vuex里面
        this.$store.dispatch("saveCartCount", res.data);
      });
    }
  }
};
</script>

<style lang="scss">
// @import '@/assets/scss/index.scss'; // 引入重置样式表（覆盖浏览器用户代理样式）
// @import '@/assets/scss/modal.scss'; // 引入模态框样式表
.mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-image: url(./assets/loading.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 100000;
  background-size: 100% 100%;
}
* {
  padding: 0;
  margin: 0;
}
#app .el-backtop {
  background-color: $colorA;
  color: wheat;
  font-size: 10px;
  width: 35px;
  height: 40px;
  padding: 0 3px;
  text-align: center;
  border-radius: 10px;
}
</style>
