import request from "@/utils/request";
// 获取验证码
export function loginCaptcha(data) {
  return request({
    url: "/api/loginCaptcha",
    method: "get",
    data: data,
  });
}
// 注册
export function register(data) {
  return request({
    url: "/api/register",
    method: "post",
    data: data,
  });
}
// 登录
export function login(data) {
  return request({
    url: "/api/login",
    method: "post",
    data: data,
  });
}
// 获取用户信息
export function userInfo(data) {
  return request({
    url: "/api/user/quit",
    method: "post",
    data: data,
  });
}
// 获取购物车数量
export function cartNum(data) {
  return request({
    url: "/api/cart/count",
    method: "post",
    data: data,
  });
}
// 获取用户收货地址
export function addressList(data) {
  return request({
    url: "/api/user/address/list",
    method: "post",
    data: data,
  });
}
// 添加用户收货地址
export function addAddress(data) {
  return request({
    url: "/api/user/address/add",
    method: "post",
    data: data,
  });
}
// 编辑用户收货地址
export function editAddress(data) {
  return request({
    url: "/api/user/address/edit",
    method: "post",
    data: data,
  });
}
// 删除用户收货地址
export function delAddress(data) {
  return request({
    url: "/api/user/address/del",
    method: "post",
    data: data,
  });
}
// 创建订单
export function orderIns(data) {
  return request({
    url: "/api/order/ins",
    method: "post",
    data: data,
  });
}
// 我的收藏
export function collectionList(data) {
  return request({
    url: "/api/goods/collection/list",
    method: "post",
    data: data,
  });
}
// 自提点列表
export function mentionsPage(data) {
  return request({
    url: "/api/mentions",
    method: "post",
    data: data,
  });
}
// 自提点详情
export function mentionDetail(data) {
  return request({
    url: "/api/mentionDetail",
    method: "post",
    data: data,
  });
}
// 支付状态
export function wxRet(data) {
  return request({
    url: "/api/wx/pc/getOrder",
    method: "post",
    data: data,
  });
}



