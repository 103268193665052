<template>
  <div>
    <TopHeader />
    <NavHeaderFixed />
    <router-view />
    <nav-footer />
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader";
import NavHeaderFixed from "@/components/NavHeaderFixed.vue";
import NavFooter from "@/components/NavFooter.vue";
export default {
  name: "nav-home",
  components: { TopHeader, NavHeaderFixed, NavFooter }
};
</script>

<style lang='scss' scoped>
</style>