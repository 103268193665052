<template>
  <div class="banner-box">
    <el-carousel height="431px" :autoplay="true">
      <el-carousel-item v-for="item in imgUrls" :key="item.id">
        <a :href="item.jump_url">
          <img :src="item.img_url" alt />
        </a>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { bannerList } from "@/api/home.js";
export default {
  data() {
    return {
      imgUrls: []
    };
  },
  created() {
    this.getBannerList();
  },
  methods: {
    getBannerList() {
      bannerList({}).then(res => {
        if (res.statusCode == 200) {
          this.imgUrls = res.data;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.banner-box {
  width: $widthBanner;
  margin: auto;
  height: 431px;
}
img {
  height: 431px;
  width: 100%;
}

.banner-box ::v-deep .el-carousel__arrow {
  width: 50px;
  height: 80px;
  border-radius: 4px;
  background-color: $colorA;
  font-size: 50px;
}
.banner-box ::v-deep .el-carousel__button {
  width: 50px;
  height: 10px;
  border-radius: 4px;
  background-color: $colorA;
  font-size: 30px;
}
</style>