
const TokenKey = 'YUNSUPCSHOP-TOKEN'

export function getToken() {
    // return Cookies.get(TokenKey)
    return window.localStorage.getItem(TokenKey)
}

export function setToken(token) {
    // return Cookies.set(TokenKey, token)
    return window.localStorage.setItem(TokenKey, token)
}

export function removeToken() {
    // return Cookies.remove(TokenKey)
    return window.localStorage.removeItem(TokenKey)
}