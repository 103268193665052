<template>
  <div class="notice-bar" @click="tipClick">
    <div class="notice-bar__icon">
      <img :src="noticeImg" />
    </div>
    <div ref="wrap" class="notice-bar__wrap">
      <div ref="content" class="notice-bar__content" :style="contentStyle">{{ noticeMesg }}</div>
    </div>
  </div>
</template>

<script>
import noticeImg from "@/assets/notice.png";
import { notice } from "@/api/home";
export default {
  name: "NoticeBar",
  props: {
    // noticeMesg: {
    //   type: String,
    //   default: ""
    // },
    speed: {
      type: Number,
      default: 30
    },
    defaultWidth: {
      type: Number,
      default: 600
    }
  },
  data() {
    return {
      noticeMesg: "公告消息公告消息公告消息",
      noticeImg,
      contentStyle: {
        transitionDuration: "0s",
        transform: "translateX(0px)"
      },
      wrapWidth: 0,
      contentWidth: 0,
      time: 0,
      timer: null,
      convertSpeed: 1
    };
  },
  created() {
    this.getNotice();
  },
  mounted() {
    if (this.noticeMesg) {
      this.init();
    }
  },
  watch: {
    noticeMesg(val) {
      this.init();
    }
  },
  methods: {
    getNotice() {
      notice({}).then(res => {
        this.noticeMesg = res.data.content;
      });
    },
    init() {
      // const _width = window.innerWidth;
      // console.log(_width)
      this.convertSpeed = (600 / this.defaultWidth) * this.speed; // 根据分辨率转化成不同的速度
      this.wrapWidth = this.$refs.wrap.offsetWidth;
      this.contentWidth = this.$refs.content.offsetWidth;
      this.startAnimate();
      this.timer = setInterval(() => {
        this.startAnimate();
      }, this.time * 1000);
      this.$once("hook:beforeDestroy", () => {
        clearInterval(this.timer);
        this.timer = null;
      });
    },
    startAnimate() {
      this.contentStyle.transitionDuration = "0s";
      this.contentStyle.transform = "translateX(" + this.wrapWidth + "px)";
      this.time = (this.wrapWidth + this.contentWidth) / this.convertSpeed;
      setTimeout(() => {
        this.contentStyle.transitionDuration = this.time + "s";
        this.contentStyle.transform =
          "translateX(-" + this.contentWidth + "px)";
      }, 300);
    },
    tipClick() {
      this.$emit("click");
    }
  }
};
</script>
<style scoped lang='scss'>
.notice-bar {
  position: relative;
  width: 600px;
  height: 30px;
  padding-left: 0;
  padding-right: 0;
  color: #868daa;
  display: flex;
  align-items: center;
  .notice-bar__icon {
    width: 28px;
    height: 28px;
    margin-right: 2px;
    img {
      width: 100%;
    }
  }
  .notice-bar__wrap {
    position: relative;
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;
    overflow: hidden;
    .notice-bar__content {
      position: absolute;
      white-space: nowrap;
      transition-timing-function: linear;
    }
  }
}
</style>