/**
 * 商城Vuex-mutation
 */
export default {
  saveUserName(state, username) {
    state.username = username;
  },
  saveCartCount(state, count) {
    state.cartCount = count;
  },
  saveLogoImg(state, logoImg) {
    state.logoImg = logoImg;
  },
  savekeywords(state, keywords) {
    state.keywords = keywords;
  },
  savedescription(state, description) {
    state.description = description;
  },
};
